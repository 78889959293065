import React, { useState, useEffect } from 'react';
import { format } from 'date-fns'
import { css } from '@mui/system';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Stack from "@mui/material/Stack";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import Chip from '@mui/material/Chip';
import { useParams } from 'react-router-dom';
import Order from '../../../common/models/order';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
// ICONS
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import ChromeReaderModeIcon from '@mui/icons-material/ChromeReaderMode';
import LinkIcon from '@mui/icons-material/Link';
import BorderColorIcon from '@mui/icons-material/BorderColor';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import SimCardDownloadIcon from '@mui/icons-material/SimCardDownload';
import CancelSharpIcon from '@mui/icons-material/CancelSharp';
import Modal from '@mui/material/Modal';
import Vendor from '../../../common/models/vendor'
import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector';
import DoneIcon from '@mui/icons-material/Done';
import TimeIcon from '@mui/icons-material/AccessTime';
import WarningIcon from '@mui/icons-material/Warning';
import AddIcon from '@mui/icons-material/Add';
import CircleRoundedIcon from '@mui/icons-material/CircleRounded';
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';
import ErrorRoundedIcon from '@mui/icons-material/ErrorRounded';
import InsertDriveFileOutlinedIcon from '@mui/icons-material/InsertDriveFileOutlined';
import DescriptionIcon from '@mui/icons-material/Description';
import MailIcon from '@mui/icons-material/Mail';
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
import BalanceIcon from '@mui/icons-material/Balance';


import moment from 'moment';
import Dialog from '@mui/material/Dialog';
import CircularProgress from '@mui/material/CircularProgress';
import DialogContent from '@mui/material/DialogContent';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { formatPhoneNumber } from '../../../common/utils';
import getJWT from 'common/authentication/jwt';

const TableContainerStyled = styled(TableContainer)(css`
  .MuiTableCell-head .MuiSvgIcon-root {
    color: #5a5a5b
  }
`);

const TableBodyStyled = styled(TableBody)(css`
  th {
    padding-left: 16px !important;
  }
`);
const TableCellStyled = styled(TableCell)(css`
  border: 0px !important;
  padding: 6px 2px;
  font-size: 11px;

  .MuiStep-root {
    margin-top: 16px;
  }
  .MuiStepLabel-label {
    font-size: 10px;
    padding-top: 4px;
    margin-top: 4px !important;
  }
  .MuiChip-label {
            font-size: 10px;
            padding: 3px 9px
        }
   button {
            font-size: 10px;
            padding: 3px 9px
        }
`);

const ButtonMargin = styled(Button)(css`
    margin: 10px
`);

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});


const VendorRemovalConfirmationModal = ({ open, handleClose }) => {
  return (
    <React.Fragment>
      <Modal
        open={open}
        onClose={() => handleClose(false)}
      >
        <Box 
          sx={{ 
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 420,
            borderRadius: '5px', 
            bgcolor: 'background.paper',  
            boxShadow: 24,
            pt: 2,
            px: 4,
            pb: 3
          }}>
          <Typography sx={{fontWeight: 'bold'}}>Remove Vendor</Typography>
          <Typography sx={{fontSize: '14px'}}>Remove the selected vendor from this assignment?</Typography>
          <Box sx={{textAlign: 'center', paddingTop: '24px'}}>
            <Button onClick={() => handleClose(false)} sx={{marginRight: '24px'}}>Cancel</Button>
            <Button onClick={() => handleClose(true)} variant="contained">Remove Vendor</Button>
          </Box>
        </Box>
      </Modal>
    </React.Fragment>
  );
}

const FirmRemovalConfirmationModal = ({ open, handleClose }) => {
  return (
    <React.Fragment>
      <Modal
        open={open}
        onClose={() => handleClose(false)}
      >
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 420,
            borderRadius: '5px',
            bgcolor: 'background.paper',
            boxShadow: 24,
            pt: 2,
            px: 4,
            pb: 3
          }}>
          <Typography sx={{ fontWeight: 'bold' }}>Remove Firm</Typography>
          <Typography sx={{ fontSize: '14px' }}>Remove the selected firm from this assignment?</Typography>
          <Box sx={{ textAlign: 'center', paddingTop: '24px' }}>
            <Button onClick={() => handleClose(false)} sx={{ marginRight: '24px' }}>Cancel</Button>
            <Button onClick={() => handleClose(true)} variant="contained">Remove Vendor</Button>
          </Box>
        </Box>
      </Modal>
    </React.Fragment>
  );
}


export default function OrderPartsSubPage({ setSelectedCompanyId, selectedCompanyId, orderid, setShowAttorneyDetails, setShowFirmDetails, partId, setPartId }) {
  const { orderid: orderIdParam } = useParams();
  orderid = Number(orderid || orderIdParam);
  const [showRemovalModal, setShowRemovalModal] = useState(false);
  const [showRemovalFirmModal, setShowRemovalFirmModal] = useState(false);
  const [partIndex, setPartIndex] = useState(0);
  const [removeVendorDetail, setRemoveVendorDetail] = useState(undefined);
  let [data, setData] = useState({ doc: [], reject: [] });
  const [isSentMessageModalOpen, setIsSentMessageModalOpen] = useState(false);
  const [sentMessageInfo, setSentMessageInfo] = useState(null);
  const [openSnackAlert, setOpenSnackAlert] = useState(false);
  const [showSnackbar, setShowSnackbar] = useState(false)
  const [snackbarType, setSnackbarType] = useState('success')
  const [alertContent, setAlertConent] = useState('')

  const handleAlertOpen = () => {
    setOpenSnackAlert(true);
  };
  const handleAlertClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnackAlert(false);
  };

  const [downloadprogress, setDownloadprogress] = useState(false);
  const [downloadLink, setDownloadLink] = useState(false);

  const vendor = (new Vendor);
  const order = (new Order);

  const steps = [
    "DescriptionIcon",
    "PersonAddIcon",
    "MailIcon",
    'AssignmentIndIcon',
    'AssignmentTurnedInIcon'
  ];

  const RejectedSteps = [
    "assign_vendor_dts",
    "document_ready",
    'link_sent',
    'start_button_clicked',
    'rejected_date'
  ];


  const preliminarySteps = [
    "DescriptionIcon",
    "PersonAddIcon",
    "SearchIcon",
    'AssignmentTurnedInIcon',
  ]

  useEffect(async () => {
    if (data.doc?.length === 0 && data.reject?.length === 0) {
      const order = (new Order);
      const result = await order.getParts(orderid);
      formatPartData(result);
    }
  }, [data.doc, data.reject]);

  useEffect(() => {
    if (partId && selectedCompanyId)
      onAssignFirmConfirmation(partId, selectedCompanyId)
  }, [selectedCompanyId])


  const onAssignFirmConfirmation = async (partid, selectedCompanyId) => {
    const result = await order.assignOrderPartToLawFirm(partid, selectedCompanyId);
    if (result.status == 'success') {
      setAlertConent('Successfully Assigned')
      setSnackbarType('success')
      setShowFirmDetails(false);
      const result = await order.getParts(orderid);
      setData(result);
      setSelectedCompanyId();
    }
    else {
      setShowSnackbar(true)
      setAlertConent('Assign Failure')
      setSnackbarType('error')
    }
  }

  const closeRemovalFirmModal = async (confirmed) => {
    if (confirmed) {
      const result = await vendor.unAssignLawFirm(data.parts[partIndex].partid);
      if (result.status == 'success') {
        const result = await order.getParts(orderid);
        setData(result);
      }
    }
    setShowRemovalFirmModal(false)
  }

  const onUnassignFirm = (idx) => {
    setPartIndex(idx)
    setShowRemovalFirmModal(true)
  }

  const onAssignFirm = (partid) => {
    setPartId(partid);
    setShowFirmDetails(true);
  }



  const formatPartData = (result) => {
    let docData = { doc: [], reject: [] }
    result.parts.forEach((rec) => {
      if (rec.part_statusid === 35) {
        if (!rec.timeline.rejected_date) {
          rec.timeline.rejected_date = moment(rec.rejection_date).format("M-DD-yyyy h:mm")
        }
        docData.reject.push(rec);
      }
      else
        docData.doc.push(rec);
    });

    setData(docData);
  }
  const closeRemovalModal = async (confirmed) => {
    if (confirmed) {
      const result = await vendor.unassignVendor(removeVendorDetail.vendorId, removeVendorDetail.partId);
      if (result.status == 'success') {
        const result = await order.getParts(orderid);
        formatPartData(result);
      }
    }
    setRemoveVendorDetail(undefined);
    setShowRemovalModal(false)
  }

  const onUnassign = (vendorId, partId) => {
    setRemoveVendorDetail({ vendorId, partId });
    setShowRemovalModal(true)
  }

  const onAssign = (partid) => {
    setPartId(partid)
    setShowAttorneyDetails(true)
  }

  const handleSentMessageModalOpen = (data) => {
    setIsSentMessageModalOpen(!isSentMessageModalOpen);
    setSentMessageInfo(data);
  };

  const handleSentMessageModalClose = () => {
    setIsSentMessageModalOpen(false);
  };

  const getDownloadLink = async (partid) => {

    const jwt = getJWT();

    setDownloadprogress(true);

    const result = await order.getPartDownloadLink(partid);

    setDownloadLink(`/grandcentral/vendor/download/${result.uuid}?token=${jwt}`);
  }

  const DocTable = () => {
    return (<TableContainerStyled>
      <Table sx={{ minWidth: 650 }} aria-label="">
        <TableHead>
          <TableRow className="document-header">
            <TableCell sx={{width:'230px'}}> ACTIVE DOCUMENTS</TableCell>
            <TableCell  sx={{width:'100px'}} align="center">FIRM</TableCell>
            <TableCell sx={{width:'150px'}} align="center">VENDOR</TableCell>
            <TableCell sx={{width:'150px'}} align="center">VENDOR EMAIL</TableCell>
            <TableCell align="center" sx={{ width: '130px' }}>VENDOR PHONE</TableCell>
            <TableCell  className='text-center ' sx={{width:'50px'}} ><DescriptionIcon/></TableCell>
              <TableCell  className='text-center ' sx={{width:'50px'}}><PersonAddIcon/></TableCell>
              <TableCell className='text-center ' sx={{width:'50px'}}><MailIcon/></TableCell>
              <TableCell className='text-center ' sx={{width:'50px'}}><AssignmentIndIcon/></TableCell>
              <TableCell  className='text-center ' sx={{width:'50px'}}><AssignmentTurnedInIcon/></TableCell>
            <TableCell  sx={{width:'50px'}} >
              <SimCardDownloadIcon />
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBodyStyled>
          {data?.doc?.map((row) => (
            <TableRow
              key={row.partid}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCellStyled component="th" scope="row">
                {row.part_name}
              </TableCellStyled>
              <TableCellStyled align="center">
                {row.firm != '' && row.part_typeid ===19 ?<Button size="small" startIcon={<BalanceIcon />} variant="outlined" onClick={() => onAssignFirm(row.partid)}>Assign</Button> 
                :<Box sx={{ position: 'relative' }}>
                    <Chip variant="outlined" onDelete={(event) => {
                      event.stopPropagation();
                        onUnassignFirm(row.partid);
                    }
                    } label={`${row.firm ? row.firm :''}`} />
                  </Box>}
              </TableCellStyled>
              <TableCellStyled align="center">
                {(row.acceptedby > 0 || row.assignedto > 0)  &&
                  <Box sx={{ position: 'relative' }}>
                    <Chip variant="outlined" onDelete={(event) => {
                      event.stopPropagation();
                      if (row.acceptedby > 0) {
                        onUnassign(row.acceptedby, row.partid);
                      }
                      else if (row.assignedto > 0) {
                        onUnassign(row.assignedto, row.partid);
                      }
                    }
                    } label={row.firstname + ' ' + row.lastname} />
                  </Box>
                }
                {((row.acceptedby == 0 && row.assignedto == 0) || row.order_status == 2) && row.part_typeid !==19 && <Button startIcon={<PersonAddIcon />} variant="outlined" onClick={() => onAssign(row.partid)}>Assign</Button>}
              </TableCellStyled>
              <TableCellStyled align="center" color="primary"><Link href="#">{row.email}</Link></TableCellStyled>
              <TableCellStyled align="center" sx={{ width: '130px' }}>{row.phone && formatPhoneNumber(row.phone)}</TableCellStyled>
                {steps.map((icon)=>{
               return <TableCellStyled className='text-center ' sx={{width:'50px'}} >{cellContent(icon,row)}</TableCellStyled>
             })}
              <TableCellStyled sx={{width:'50px'}} align="center">
                {row.part_status == 'Completed' &&
                  <SimCardDownloadIcon
                  color={'primary'}
                  onClick={(event) => {
                    getDownloadLink(row.partid);
                    event.stopPropagation();
                  }}
                />}
              </TableCellStyled>
            </TableRow>
          ))}
        </TableBodyStyled>
      </Table>
    </TableContainerStyled>);
  }

  const RejectTable = () => {
    return (<TableContainerStyled>
      <Table sx={{ minWidth: 650 }} aria-label="">
        <TableHead>
          <TableRow className="document-header">
            <TableCell sx={{width:'230px'}}>REJECT DOCUMENTS</TableCell>
            <TableCell sx={{width:'100px'}} align="center">FIRM</TableCell>
            <TableCell sx={{width:'150px'}} align="center">VENDOR</TableCell>
            <TableCell sx={{width:'150px'}} align="center">VENDOR EMAIL</TableCell>
            <TableCell align="center" sx={{ width: '130px' }}>VENDOR PHONE</TableCell>
            <TableCell className='text-center ' sx={{width:'50px'}} ><DescriptionIcon/></TableCell>
              <TableCell className='text-center ' sx={{width:'50px'}} ><PersonAddIcon/></TableCell>
              <TableCell className='text-center ' sx={{width:'50px'}} ><MailIcon/></TableCell>
              <TableCell className='text-center ' sx={{width:'50px'}} ><AssignmentIndIcon/></TableCell>
              <TableCell className='text-center ' sx={{width:'50px'}} ><AssignmentTurnedInIcon/></TableCell>
            <TableCell  sx={{width:'50px'}} align="center">
              <SimCardDownloadIcon />
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBodyStyled>
          {data?.reject?.map((row) => (
            <TableRow
              key={row.partid}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCellStyled component="th" scope="row">
                {row.part_name}
              </TableCellStyled>
              <TableCellStyled align="center">
                {row.firm}
              </TableCellStyled>
              <TableCellStyled align="center">
                {row.firstname?row.firstname:'' + ' ' + row.lastname?row.lastname:''}
              </TableCellStyled>
              <TableCellStyled align="center" color="primary"><Link href="#">{row.email}</Link></TableCellStyled>
              <TableCellStyled align="center" sx={{ width: '130px' }}>{row.phone && formatPhoneNumber(row.phone)}</TableCellStyled>
              {steps.map((icon)=>{
               return <TableCellStyled className='text-center ' sx={{width:'50px'}} >{cellContent(icon,row)}</TableCellStyled>
             })}
             <TableCellStyled  sx={{width:'50px'}} align="center">
                {row.part_status == 'Completed' && <SimCardDownloadIcon color={'primary'} />}
              </TableCellStyled>
            </TableRow>
          ))}
        </TableBodyStyled>
      </Table>
    </TableContainerStyled>);
  }

  return (
    <>
      <Typography variant="h4" component="h4" style={{ padding: "15px" }}>
        Documents
      </Typography>
      {downloadprogress && (
        <Dialog
          open={downloadprogress}
          onClose={ (event) => {
            setDownloadprogress(false);
            setDownloadLink(false);
          }}
        >
          { (downloadLink && downloadLink != ""  ?
              <DialogContent sx={{textAlign:"center"}}>
                {downloadLink.indexOf("download/false")>0 ? "Document Not Found" : "Document ready"}<br />
                <Button sx={{marginTop:"15px"}} variant="contained" target="_blank" href={downloadLink} endIcon={<FileDownloadIcon />} disabled={downloadLink.indexOf("download/false")>0}>
                  Download Now
                </Button>
              </DialogContent>
            :
              <DialogContent sx={{textAlign:"center"}}>
                Preparing Document <br /><CircularProgress />
              </DialogContent> 
          ) }
        </Dialog>
      )}
      {data.doc.length > 0 && <DocTable />}
      {data.reject.length > 0 && <RejectTable />}
      <VendorRemovalConfirmationModal open={showRemovalModal} handleClose={closeRemovalModal} />
       <FirmRemovalConfirmationModal open ={showRemovalFirmModal} handleClose={closeRemovalFirmModal} />
      <Snackbar open={showSnackbar} autoHideDuration={4000} onClose={()=>setShowSnackbar(false) }>
      <Alert onClose={()=>setShowSnackbar(false)} severity={snackbarType} sx={{ width: '100%' }}>
        {alertContent}
      </Alert>
    </Snackbar>
    </>
  );
}

const cellContent = (column, row) => {
  const icon_size = '20px';
  const circle_default = <Box sx={{display:'block'}}><CircleRoundedIcon sx={{ width: icon_size, height: icon_size, fill: '#c4c4c6' }} /><div className='date-box-height'></div></Box>;
  const circle_success = <CheckCircleRoundedIcon sx={{ width: icon_size, height: icon_size, fill: '#1de528' }} />;
  const circle_error = <ErrorRoundedIcon sx={{ width: icon_size, height: icon_size, fill: '#e32d16' }} />;

  const SucessIcon = ({ date }) => {
    console.log("date--", date);
    return (
      <Box sx={{display:'block'}}>
  <CheckCircleRoundedIcon sx={{ width: icon_size, height: icon_size, fill: '#1de528' }} />
  <div className='date-box-height'>{date!=null ? moment(date).format("M/DD/YY"):''}</div>
  </Box>)
  }

  const ErrorIcon = ({ date }) => {
    console.log("date--", date);
    return (
      <Box sx={{display:'block'}}>
  <ErrorRoundedIcon sx={{ width: icon_size, height: icon_size, fill: '#e32d16' }} />
  <div className='date-box-height'>{ date!=null ? moment(date).format("M/DD/YY"):''}</div>
  </Box>
    )
  }

  const prop = row;
  switch (column) {
    case "DescriptionIcon":
      let partstatus;
      const part_prelim = prop?.part_name?.includes('Preliminary Attorney');
      const part_final = prop?.part_name?.includes('Final Attorney');

      if (part_final) {
        partstatus = 'F';
      }
      else if (part_prelim) {
        partstatus = 'P';
      }
      else {
        return <div className="relative"><InsertDriveFileOutlinedIcon sx={{ width: '20px', height: '30px', fill: '#c4c4c6' }} /><span className="subparttype">{partstatus}</span></div>;
      }
      return <div className="relative"><InsertDriveFileOutlinedIcon sx={{ width: '20px', height: '30px', fill: '#c4c4c6' }} /><span className="subparttype">{partstatus}</span></div>
    case "PersonAddIcon":
      return ([1, 2].indexOf(prop?.part_statusid) === -1 && prop.acceptedby > 0 && prop.assignedto > 0) ? <SucessIcon date={prop.timeline.assign_vendor_dts} /> : circle_default;
    case "MailIcon":
      return prop?.outbound_emails_total > 0 ? <SucessIcon date={prop.timeline.link_sent} /> : circle_default;
    case "AssignmentIndIcon":
      return [3, 4, 5, 6, 7, 35, 13].indexOf(prop?.part_statusid) !== -1 ? <SucessIcon date={prop.timeline.start_button_clicked} /> : circle_default;
    case "AssignmentTurnedInIcon":
      return [6, 7].indexOf(prop?.part_statusid) !== -1 ? <SucessIcon date={prop.timeline.completed_date} /> : prop?.part_statusid === 35 ? <ErrorIcon date={prop.rejection_date}/> : circle_default;
    default:
      return circle_default;
  }
}


function isSameDay(d1, d2) {
  if (!d2) return false
  d2 = new Date(d2);
  return d1.getFullYear() === d2.getFullYear() &&
    d1.getDate() === d2.getDate() &&
    d1.getMonth() === d2.getMonth();
}

function isPastDay(d1, d2) {
  if (!d2) return false
  d2 = new Date(d2);

  return d1.getFullYear() > d2.getFullYear() ||
    d1.getDate() > d2.getDate() ||
    d1.getMonth() > d2.getMonth();
}
